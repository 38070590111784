
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FaizOranList from "@/components/lists/FaizOranList.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {FaizOranEntity} from "@/entity/FaizOranEntity";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";

@Component({
  components: {
    FormWrapper,
    FaizOranList,
  },
})
export default class FaizTuruForm extends Mixins(ObjectInputMixin) {
  @Prop() takipTarihi!: Date;
  @Prop() hideButtonsClose!: string | boolean;
  @Prop() hideButtonsSubmit!: string | boolean;
  items: Array<FaizTuruEntity> = [];
  infoAlert:boolean = false

  get isHideButtonsSubmit() {
    if (this.localValue.standart_mi){
      return this.hideButtonsSubmit = true
    }else{
      return this.hideButtonsSubmit || this.hideButtonsSubmit == '';
    }

  }
  get isHideButtonsClose() {
      return this.hideButtonsClose || this.hideButtonsClose == '';
  }


  onClose(){
    this.infoAlert=false
    this.$emit("onClose");

  }


  load() {
    this.$emit("load");
  }

  async save() {
    let response = null;
    if (this.localValue.faiz_oranlari.length<1){
      this.infoAlert =true
    }else{
      this.infoAlert=false
      if (this.localValue && this.localValue.id != null) {
        response = await this.$http.put("/api/v1/faiz-turu/" + this.localValue.id, this.localValue);
      } else {
        if (this.$route.params.id) {
          response = await this.$http.post("/api/v1/takip/" + this.$route.params.id + "/faiz-turu/", this.localValue);
        } else {
          response = await this.$http.post("/api/v1/faiz-turu/", this.localValue);
        }
      }
      this.items = response.data;
      this.localValue.id = response.data.id;
      await this.saveOranlar(this.localValue);
      this.$emit("onSuccess");
      return response.data.id;
    }
  }

  async saveOranlar(item: FaizTuruEntity) {
    if (item.faiz_oranlari) {
      for (const faizOran of item.faiz_oranlari) {
        if (faizOran && faizOran.id != null) {
          await this.$http.put("/api/v1/faiz-oran/" + faizOran.id, faizOran);
        } else {
          faizOran.faiz_turu_id = item.id;
          await this.$http.post("/api/v1/faiz-oran/", faizOran);
        }
      }
    }
    this.load();
    this.$emit("onClose");
  }
}
