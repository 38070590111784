
import { Component, Mixins, Prop } from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import { KisiKurumTuru } from "@/enum/KisiKurumTuru";

@Component({})
export default class platform extends Mixins(SimpleInputMixin) {
  @Prop() required: any;
  kisi_kurum_turu = KisiKurumTuru;

  options = [
    { label: 'Kişi', value: this.kisi_kurum_turu.sahis, icon: 'mdi-account' },
    { label: 'Kurum', value: this.kisi_kurum_turu.sirket, icon: 'mdi-domain' }
  ];

  get isRequired() {
    return this.required || this.required === "";
  }
}
