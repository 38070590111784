
import {Component, Prop, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import HacizForm from "@/components/forms/haciz/HacizForm.vue";
import EskiSatisForm from "@/components/forms/satis/EskiSatisForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import {HacizEntity} from "@/entity/Haciz/HacizEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {HacizTuru} from "@/enum/haciz/HacizTuru";
import MasrafForm from "@/components/forms/MasrafForm.vue";
import {NotTuru} from "@/enum/NotTuru";
import NotList from "@/components/lists/NotList.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import MasrafHarcForm from "@/components/forms/MasrafHarcForm.vue";
import HacizMasrafList from "@/components/forms/HacizMasrafList.vue";
import SatisMasrafList from "@/components/forms/SatisMasrafList.vue";
import {SatisYapilamamaSebebiLabel} from "@/enum/SatisYapilamamaSebebi";

@Component({
  components: {
    SatisMasrafList,
    HacizMasrafList,
    MasrafHarcForm,
    FormWrapper,
    NotList,
    MasrafForm,
    FormDialog,
    EnabledSwitch,
    SatisForm: EskiSatisForm,
    HacizForm,
    DeleteDialog,
  }
})
export default class HacizList extends Mixins(ObjectInputMixin) {
  @Prop() borclu!: BorcluEntity;

  haciz: HacizEntity = new HacizEntity();
  hacizTuru = HacizTuru;
  notTuru = NotTuru;
  satisYapilmamaSebebiLabel = SatisYapilamamaSebebiLabel


  satisHeaders = [
    {text: "Satış", value: "satis_no", sortable: false},
    {text: "Başlangıç Tarihi", value: "baslangic_tarihi", sortable: false},
    {text: "Satış Yapıldı Mı", value: "satis_yapildi_mi", sortable: false},
    {text: "Sebep", value: "satis_yapilamama_sebebi", sortable: false},
    {text: "İşlemler", value: "actions", sortable: false, align: 'center', width: 150},
  ]

  headers = [
    {text: "Türü", align: "start", value: "haciz_turu.isim"},
    {text: "Sırası", value: "haciz_sirasi", sortable: false},
    {text: "Haciz Tarihi", align: "start", value: "haciz_tarihi"},
    {text: "Satış Avansı Yatırılma Tarihi", value: "haciz_avansi_yatirilma_tarihi", sortable: false},
    {text: 'Satış', value: 'data-table-expand', align: 'center'},
    {text: "İşlemler", value: "actions", width: 150, sortable: false, align: 'center'},
  ]

  /*showFooter(haciz: HacizEntity) {
    if (haciz.satis_talepleri.length > 0 && haciz.satis_talepleri[haciz.satis_talepleri.length - 1].ikinci_satis && !haciz.satis_talepleri[haciz.satis_talepleri.length - 1].ikinci_satis.satis_yapildi_mi)
      return true;
    else return false;
  }*/

  load() {
    this.$emit("load");
  }

  // TODO: Yeni satış yapısı netleşene kadar satış eklenemeyecektir
  /*getSatislar(haciz: HacizEntity) {
    let satislar:any = [];
    // if (haciz.birinci_satis) satislar.push({...haciz.birinci_satis, satis_no: 1});
    // if (haciz.ikinci_satis) satislar.push({...haciz.ikinci_satis, satis_no: 2});
    // Yeni haciz satışı
    haciz.satis_talepleri.forEach((satisTalebi:any, index:number) => {
      if (satisTalebi.birinci_satis) satislar.push({
        ...satisTalebi.birinci_satis,
        satis_no: 1,
        satis_talebi_index: index
      });
      if (satisTalebi.ikinci_satis) satislar.push({
        ...satisTalebi.ikinci_satis,
        satis_no: 2,
        satis_talebi_index: index
      });
    })
    return satislar;
  }*/
}
