import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {Vue} from "vue-property-decorator";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import AlacakliData from "@/classes/evrak/AlacakliData";
import {AdresEntity} from "@/entity/AdresEntity";
import {AvukatEntity} from "@/entity/AvukatEntity";
import {AdresTuru} from "@/enum/AdresTuru";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import BorcluData from "@/classes/evrak/BorcluData";
import {BorcluVekiliEntity} from "@/entity/BorcluVekiliEntity";
import {TasinirEntity} from "@/entity/AlacakBelgeler/TasinirEntity";
import {MalikEntity} from "@/entity/AlacakBelgeler/MalikEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import AlacakKalemiData from "@/classes/evrak/AlacakKalemiData";
import {IlamTipi} from "@/enum/IlamTipi";
import TerekeData from "@/classes/evrak/TerekeData";
import {EvrakMaddeler} from "@/enum/EvrakMaddeler";
import {TasinmazEntity} from "@/entity/Haciz/TasinmazEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {TakipTipi} from "@/enum/TakipTipi";
import {TebligatEntity} from "@/entity/TebligatEntity";

export default class TakipTalebiDataProvider extends Vue {
    public static instance: TakipTalebiDataProvider;

    AlacakKalemiAlani = AlacakKalemiAlani;

    paraBirimleri: Map<number, ParaBirimiEntity> = new Map<number, ParaBirimiEntity>();

    public static getInstance() {
        if (!this.instance) {
            this.instance = new TakipTalebiDataProvider();
        }

        return this.instance;
    }

    getAciklama(maddeNo: EvrakMaddeler, alacakBelgeTuruId: AlacakBelgeTuru | null, ...props: Array<any>): any {
        let methodName = "get" + this.getNameByTakipTalebiMadde(maddeNo) + this.getNameByAlacakBelgeTuru(alacakBelgeTuruId) + "Aciklamasi";
        if ((this as any)[methodName]) {
            return (this as any)[methodName](props);
        }
    }

    private getNameByTakipTalebiMadde(maddeNo: EvrakMaddeler) {
        switch (maddeNo) {
            case EvrakMaddeler.AlacakliData:
                return "AlacakliData";
            case EvrakMaddeler.BorcluData:
                return "BorcluData";
            case EvrakMaddeler.TerekeData:
                return "TerekeData";
            case EvrakMaddeler.AlacakKalemiData:
                return "AlacakKalemiData";
            case EvrakMaddeler.RehinData:
                return "RehinData";
            case EvrakMaddeler.IlamData:
                return "IlamData";
            case EvrakMaddeler.IlamOzetiData:
                return "IlamOzetiData";
            case EvrakMaddeler.KiraKontratiData:
                return "KiraKontratiData";
            case EvrakMaddeler.TahliyeTaahhuduData:
                return "TahliyeTaahhuduData";
            case EvrakMaddeler.AlacakData:
                return "AlacakData";
            case EvrakMaddeler.TakipYoluData:
                return "TakipYoluData";
            case EvrakMaddeler.MalikData:
                return "MalikData";
            case EvrakMaddeler.FaizData:
                return "FaizData";
            case EvrakMaddeler.AlacakKalemleriByParaBirimi:
                return "AlacakKalemleriByParaBirimi";
        }
    }

    private getNameByAlacakBelgeTuru(alacakBelgeTuruId: AlacakBelgeTuru | null) {
        switch (alacakBelgeTuruId) {
            case AlacakBelgeTuru.cek:
                return "Cek";
            case AlacakBelgeTuru.police:
                return "Police";
            case AlacakBelgeTuru.fatura:
                return "Fatura";
            case AlacakBelgeTuru.kiraKontrati:
                return "KiraKontrati"
            case AlacakBelgeTuru.sozlesme:
                return "Sozlesme";
            case AlacakBelgeTuru.nafaka:
                return "Nafaka";
            case AlacakBelgeTuru.iseIadeKarari:
                return "IseIade"
            case AlacakBelgeTuru.tuketiciHakemHeyetiKarari:
                return "TuketiciHakemHeyeti";
            case AlacakBelgeTuru.mts:
                return "MTS"
            case AlacakBelgeTuru.rehinBelgesi:
                return "RehinBelgesi"
            case AlacakBelgeTuru.senet:
                return "Senet";
            case AlacakBelgeTuru.bono:
                return "Bono";
            case AlacakBelgeTuru.isciAlacagi:
                return "IsciAlacagi";
            case AlacakBelgeTuru.tazminat:
                return "Tazminat";
            case AlacakBelgeTuru.cocukTeslimi:
                return "CocukTeslimi";
            case AlacakBelgeTuru.kisiselIliski:
                return "KisiselIliski";
            case AlacakBelgeTuru.birIsinYapilmasiYaDaYapilmamasi:
                return "BirIsinYapilmasiYadaYapilmamasi";
            case AlacakBelgeTuru.irtifakVeyaIntifakHakkiKaldirilmasi:
                return "IrtifakHakki";
            case AlacakBelgeTuru.tahliyeTaahhudu:
                return "TahliyeTaahhudu";
            case AlacakBelgeTuru.ipotekAkitTablosu:
                return "IpotekAkit";
            case AlacakBelgeTuru.noterSenedi:
                return "NoterSenedi";
            case AlacakBelgeTuru.diger:
                return "RehinBelgesi"
            default:
                return "";


        }
    }

    //#region BirinciMadde


    private getAlacakliDataAciklamasi(props: Array<any>): AlacakliData {
        let alacakli: AlacakliEntity = props[0];

        let varsayilanAdres: AdresEntity | null = this.$helper.kisiKurumVarsayilanAdres(alacakli.muvekkil)
        let vekalet: VekaletEntity | null = alacakli.muvekkil?.vekalet;
        let buroAdres!: AdresEntity;

        let alacakliData = new AlacakliData();
        alacakliData.isim = this.$helper.kisiKurumAd(alacakli.muvekkil)

        if (alacakli?.muvekkil?.kisi) {
            if (alacakli?.muvekkil?.kisi.yabanci_uyruklu_mu) {
                alacakliData.uniqueDataText = "Yabancı Kimlik No";
            } else {
                alacakliData.uniqueDataText = "TC Kimlik No";
            }
        } else if (alacakli.muvekkil.kurum) {
            alacakliData.uniqueDataText = "Vergi No";
        } else {
            alacakliData.uniqueDataText = "";
        }
        alacakliData.uniqueData = this.$helper.tcknOrVergiNo(alacakli.muvekkil);

        if (varsayilanAdres) {
            if (varsayilanAdres.adres_tur_id == AdresTuru.mernis) {
                alacakliData.adres = "Mernis Adresi"
            } else if (varsayilanAdres.adres_tur_id == AdresTuru.mersis) {
                alacakliData.adres = "Mersis Adresi"
            } else {
                alacakliData.adres = varsayilanAdres.adres ? (varsayilanAdres?.adres + " " + varsayilanAdres.ilce?.isim + " / " + varsayilanAdres.sehir?.isim) : "";
            }
        }

        alacakliData.bankaHesapBilgisi = vekalet?.varsayilan_banka_hesabi?.iban;
        alacakliData.bankaHesapBilgisiSahibi = vekalet?.varsayilan_banka_hesabi_sahibi; //varsayilan_banka_hesabi_sahibi gelmiyor artık apiden

        alacakli.muvekkil.vekalet.avukatlar.forEach((avukat: AvukatEntity) => {
            alacakliData.vekilIsimler.push(avukat.first_name + " " + avukat.last_name);
            buroAdres = avukat?.burolar[0]?.adres;
        });

        if (buroAdres) {
            if (buroAdres.adres_tur_id == AdresTuru.mernis) {
                alacakliData.vekilAdres = "Mernis Adresi"
            } else if (buroAdres.adres_tur_id == AdresTuru.mersis) {
                alacakliData.vekilAdres = "Mersis Adresi"
            } else {
                alacakliData.vekilAdres = (buroAdres?.adres ? buroAdres?.adres : "") + " " + (buroAdres?.ilce.id ? buroAdres?.ilce?.isim : "") + " / " + (buroAdres?.sehir.id ? buroAdres?.sehir?.isim : "");
            }
        }
        return alacakliData;
    }


    //#endregion BirinciMadde

    //#region IkınciMadde

    private getBorcluDataAciklamasi(props: Array<any>): BorcluData {
        let borclu: BorcluEntity = props[0];

        let varsayilanAdres: AdresEntity | null = this.$helper.kisiKurumVarsayilanAdres(borclu)
        let vekilAdres!: AdresEntity;


        let borcluData = new BorcluData();
        borcluData.borcluAdi = this.$helper.kisiKurumAd(borclu)

        if (borclu?.kisi) {
            if (borclu?.kisi.yabanci_uyruklu_mu) {
                borcluData.uniqueDataText = "Yabancı Kimlik No";
            } else {
                borcluData.uniqueDataText = "TC Kimlik No";
            }

        } else if (borclu.kurum) {
            borcluData.uniqueDataText = "Vergi No";
        } else {
            borcluData.uniqueDataText = "";
        }
        borcluData.uniqueData = this.$helper.tcknOrVergiNo(borclu);

        if (borclu.tebligatlar) {
            borclu.tebligatlar.forEach((tebligat: TebligatEntity) => {
                borcluData.checks.tebligTarihi = tebligat.teblig_tarihi;
                borcluData.checks.ikinciTebligat_check = false; //bilinmiyor sorulacak
                borcluData.checks.bilaTarihi = tebligat.bila_tarihi;
                borcluData.checks.kabulTarihi = tebligat.kesinlesme_tarihi;
                borcluData.checks.itirazVar_check = tebligat.itiraz_var_mi;
                borcluData.checks.malBeyani_check = tebligat.mal_beyaninda_bulundu_mu;
                //borcluData.checks.taahKabulAyVar_check=false; //bilinmiyor sorulacak
            })

        }


        if (varsayilanAdres) {
            if (varsayilanAdres.adres_tur_id == AdresTuru.mernis) {
                borcluData.adres = "Mernis Adresi"
            } else if (varsayilanAdres.adres_tur_id == AdresTuru.mersis) {
                borcluData.adres = "Mersis Adresi"
            } else {
                borcluData.adres = varsayilanAdres?.adres + " " + varsayilanAdres?.ilce?.isim + " / " + varsayilanAdres?.sehir?.isim;
            }
        }

        borclu.vekiller.forEach((avukat: BorcluVekiliEntity) => {
            borcluData.vekilIsimler.push(avukat.ad + " " + avukat.soyad);
            vekilAdres = avukat?.adres;
        });

        if (vekilAdres) {
            if (vekilAdres.adres_tur_id == AdresTuru.mernis) {
                borcluData.vekilAdres = "Mernis Adresi"
            } else {
                borcluData.vekilAdres = vekilAdres?.adres + " " + vekilAdres?.ilce?.isim + " / " + vekilAdres?.sehir?.isim;
            }
        } else {
            borcluData.vekilAdres = "";
        }


        return borcluData;
    }

    //#endregion IkınciMadde

    //#region UcuncuMadde

    private getTerekeDataAciklamasi(props: Array<any>): TerekeData {
        let borclu: BorcluEntity = props[0];

        let varsayilanAdres: AdresEntity | null = this.$helper.kisiKurumVarsayilanAdres(borclu)


        let terekeData = new TerekeData();
        terekeData.borcluAdi = this.$helper.kisiKurumAd(borclu)

        if (borclu?.kisi) {
            terekeData.uniqueDataText = "TC Kimlik No";
        } else if (borclu.kurum) {
            terekeData.uniqueDataText = "Vergi No";
        } else {
            terekeData.uniqueDataText = "";
        }
        terekeData.uniqueData = this.$helper.tcknOrVergiNo(borclu);

        if (varsayilanAdres) {
            if (varsayilanAdres.adres_tur_id == AdresTuru.mernis) {
                terekeData.adres = "Mernis Adresi"
            } else if (varsayilanAdres.adres_tur_id == AdresTuru.mersis) {
                terekeData.adres = "Mersis Adresi"
            } else {
                terekeData.adres = varsayilanAdres?.adres + " " + varsayilanAdres?.ilce?.isim + " / " + varsayilanAdres?.sehir?.isim;
            }
        }

        borclu.vekiller.forEach((vekil: BorcluVekiliEntity) => {
            terekeData.vekiller.push(vekil);
        });
        return terekeData;
    }

    //#endregion UcuncuMadde

    //#region DorduncuMadde

    private getAlacakKalemiDataAciklamasi(props: Array<any>): string {
        let alacakKalemi = props[0];
        let aciklama = "";
        if (alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) {
            aciklama = alacakKalemi.baslangic_tarihi + " - " + alacakKalemi.bitis_tarihi + " arası yıllık %" + alacakKalemi.faiz_oran + "<br>"
        }
        return aciklama
    }


    getFaizDataAciklamasi(props: Array<any>): string[] {
        let alacakKalemi = props[0];
        let faizDonemListesi: string[] = [];
        let faizDetayMetni: string;
        //4.Madde Faiz Donemleri
        if (alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.takipOncesiFaiz) {

            if (alacakKalemi.aciklama == "İşlemiş Faiz") {
                faizDetayMetni = this.$helper.tutarStr(alacakKalemi.tutar, alacakKalemi.para_birimi) + " tutarında " + alacakKalemi.aciklama
            } else {
                faizDetayMetni = alacakKalemi.aciklama
            }
            //direkt apiden gelen aciklamada faiz metni oluştuğu için takip oncesi faiz ise onu attım arraya
            //faizDetayMetni = alacakKalemi.baslangic_tarihi + " - " + alacakKalemi.bitis_tarihi + " tarihleri arasında " + alacakKalemi.faiz_oran + " oranlı "+alacakKalemi.tutar+" "+alacakKalemi.para_birimi.kisa_isim+" tutarlı";
            faizDonemListesi.push(faizDetayMetni)
        }
        return faizDonemListesi;

    }


    // BAŞ


    public getAlacakKalemiTutarVeAciklama(takip: TakipEntity): Array<Array<object>> {
        let aciklama: Array<object> = [];
        let faizTop = 0;
        let kalemToplam = 0;

        let alacakToplami: any = [];

        takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
            takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
                if (takipTalebi.takip_tipi.para_alacagi_var) {
                    //FAİZ BAŞLANGIÇ TARİHİNE GORE SIRALAMA YAPILMASI İSTENİLDİ TT 4.MADDE DE
                    alacak.alacak_kalemleri.sort((a: AlacakKalemiEntity, b: AlacakKalemiEntity) => new Date(a.faiz_baslangic_tarihi).getTime() - new Date(b.faiz_baslangic_tarihi).getTime());
                    alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
                        if (alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) {
                            let alacakKalemiAciklamasi = alacakKalemi.aciklama

                            takipTalebi.alt_tip_hesap_ayar.forEach(altTip => {
                                if(altTip.etiket){
                                    if(altTip.alacak_kalemi_alani_id==alacakKalemi.alacak_kalemi_alani_id){
                                        alacakKalemiAciklamasi = altTip.etiket
                                    }
                                }
                            })

                            if ((alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.asilAlacak)) {
                                aciklama.push({
                                    tutar: this.$helper.tutarStr(alacakKalemi.tutar, alacakKalemi.para_birimi),
                                    aciklama: alacakKalemiAciklamasi
                                })
                            }
                            if (!(alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.takipOncesiFaiz)) {
                                if (alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.asilAlacak || alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.ihtarMasrafi || alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.protesto) {
                                    alacakKalemi.children.forEach((child: AlacakKalemiEntity) => {
                                        if (child.alacak_kalemi_alani_id == AlacakKalemiAlani.takipOncesiFaiz) {
                                            faizTop += Number(child.tutar);
                                        }
                                    });
                                    if(faizTop!=0) {aciklama.push({
                                        tutar: this.$helper.tutarStr(faizTop, alacakKalemi.para_birimi),
                                        aciklama: "İşlemiş Faiz"
                                    })
                                    }
                                    faizTop = 0;
                                }
                            }
                            if ((!(alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.asilAlacak) && !(alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.takipOncesiFaiz))) {
                                aciklama.push({
                                    tutar: this.$helper.tutarStr(alacakKalemi.tutar, alacakKalemi.para_birimi),
                                    aciklama: alacakKalemiAciklamasi
                                })
                            }

                        }
                        kalemToplam += Number(alacakKalemi.tahsil_edilmeyen_tutar);
                    })
                    if (aciklama.length > 0) {
                        alacakToplami.push(aciklama);
                        aciklama = [];
                    }
                }
                //54 TAKİPTE SADECE ALACAK GİRİYORUZ VE PARASIZ BİR ALACAK OLDUGU İÇİN ELSE YAZILDI
                else {
                    let alacakKalemiAciklamasi = alacak.aciklama
                    aciklama.push({
                        tutar: null,
                        aciklama: alacakKalemiAciklamasi
                    })
                    alacakToplami.push(aciklama);
                    aciklama = [];
                }
            });
        });
        return alacakToplami;
    }


    public getAlacaklarGenelToplam(takip: TakipEntity): Array<object> {
        let genelToplamVeParaBirimi: Array<object> = [];
        let AlacakByPB: Array<object> = [];

        takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
            takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
                alacak.alacak_kalemleri.sort(function (a, b) {
                    return a.para_birimi_id - b.para_birimi_id;
                });
                alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
                    if (alacakKalemi.alacak_kalemi_alani.takip_oncesi_mi) {
                        let obj:any = AlacakByPB.find((value:any) => value.para_birimi_id == alacakKalemi.para_birimi_id)
                        if (obj) {
                            obj.tutar += Number(alacakKalemi.tutar);
                        } else {
                            AlacakByPB.push({
                                para_birimi_id: alacakKalemi.para_birimi_id,
                                tutar: Number(alacakKalemi.tutar),
                                para_birimi: alacakKalemi.para_birimi,
                                kur: Number(alacakKalemi.kur),
                                vade_tarihi: (alacakKalemi.vade_tarihi) ? (alacakKalemi.vade_tarihi) : ((alacakKalemi.ibraz_tarihi) ? (alacakKalemi.ibraz_tarihi) : (alacakKalemi.faiz_baslangic_tarihi))
                            });
                        }
                    }
                });
                genelToplamVeParaBirimi.push(AlacakByPB)
                AlacakByPB = [];
            });
        });
        return genelToplamVeParaBirimi;
    }


    public getDovizDetayToplam(takip: TakipEntity): object {
        let dovizDetayMetinleri: string[] = [];
        let kurMetni: string[] = [];
        let dovizAciklama = "";
        let genelToplam = 0
        let alacakToplamlariByParaBirimi: Array<object> = [];
        let paraBirimiGrup: Array<object> = [];
        let toplamTl = 0;
        let kurAciklama = "";

        let alacakTop: Array<object> = [];
        alacakTop = this.getAlacaklarGenelToplam(takip);

        alacakTop.forEach((kalem: any) => {
                kalem.forEach((degerler: any) => {
                    let obj:any = alacakToplamlariByParaBirimi.find((value:any) => value.para_birimi_id == degerler.para_birimi_id)
                    if (obj) {
                        obj.tutar += Number(degerler.tutar);
                    } else {
                        alacakToplamlariByParaBirimi.push({
                            para_birimi_id: degerler.para_birimi_id,
                            tutar: Number(degerler.tutar),
                            para_birimi: degerler.para_birimi,
                            kur: Number(degerler.kur),
                            vade_tarihi: degerler.vade_tarihi
                        });
                    }
                });

            }
        );

        alacakToplamlariByParaBirimi.forEach((deger: any) => {
            if (deger.para_birimi_id != 1) {
                toplamTl += deger.tutar * deger.kur;
                dovizAciklama = "(" + this.$helper.tutarStr(deger.tutar, deger.para_birimi) + " X " + Number(deger.kur) + ")";
                kurAciklama = this.$helper.tarihStr(deger.vade_tarihi) + " tarihinde " + this.$helper.tutarStr(1, deger.para_birimi) + " = " + Number(deger.kur) + " TL";
                dovizDetayMetinleri.push(dovizAciklama);
                kurMetni.push(kurAciklama);
            } else {
                toplamTl += Number(deger.tutar) * Number(deger.kur);
                dovizAciklama = this.$helper.tutarStr(deger.tutar, deger.para_birimi)
                dovizDetayMetinleri.push(dovizAciklama);
            }

        });


        /*if (deger.paraBirimi != 1) {
            dovizAciklama += this.$helper.tutarStr(deger.tutar, deger.para_birimi) + " X " + deger.kur + " TL"
        } else {
            dovizAciklama += this.$helper.tutarStr(deger.tutar, deger.para_birimi)
        }
        dovizDetayMetinleri.push(dovizAciklama);*/

        return {aciklama: dovizDetayMetinleri.join(" + "), toplam: toplamTl, kurAciklama: kurMetni.join(" , ")};
    }


    public getTakipOncesiTahsilat(takip: TakipEntity): Array<object> {
        let takipOncesiTahsilatlar: Array<object> = [];

        takip.tahsilatlar.forEach((tahsilat: TahsilatEntity) => {
            if (tahsilat.tarih < takip.takip_tarihi) {
                takipOncesiTahsilatlar.push({
                    tutar: this.$helper.tutarStr(tahsilat.tutar, tahsilat.para_birimi),
                    aciklama: this.$helper.tarihStr(tahsilat.tarih) + " tarihli Tahsilat"
                })
            }
        });
        return takipOncesiTahsilatlar;
    }


    public AlacaklininTalepEttigiHak(takip: TakipEntity): string {
        return takip.alacaklinin_talep_ettigi_hak;
    }

    public KesideciMetni(takip: TakipEntity): string {
        let metin = "";
        if (takip.talepler[0].takip_tipi_id == 12 || takip.talepler[0].takip_tipi_id == 1) {
            takip.talepler[0].alacaklar.forEach((alacak: AlacakEntity) => {
                if (alacak.alacak_belge_turu_id == 1) {
                            metin = "%10 çek tazminatından keşideci sorumludur.";
                }
            })
        }
        return metin;
    }


// SON


//#endregion DorduncuMadde

//#region BesinciMadde

    private getRehinDataAciklamasi(props: Array<any>): string {
        let rehin: TasinirEntity | TasinmazEntity = props[0];
        return rehin.aciklama;
    }

    private getMalikDataAciklamasi(props: Array<any>): string {
        let rehin: TasinirEntity | TasinmazEntity = props[0];
        let aciklama = "";
        rehin.malikler?.forEach((malik: MalikEntity) => {
            aciklama += "Ad Soyad: " + malik.isim + "<br>" + " TC: " + malik.tc_no + "<br>" + "Adres: " + malik.adres
        });
        return aciklama;
    }

//#endregion Besinci

//#region AltinciMadde


    private getIlamDataAciklamasi(props: Array<any>): string {
        let alacak = props[0];

        let aciklama = "";
        switch (alacak.ilam_tipi) {
            case IlamTipi.diger:
                aciklama = alacak.ilami_veren_kurum + "'nin " + alacak.yevmiye_no + " Karar sayılı " + this.$helper.tarihStr(alacak.karar_tarihi) + " karar tarihli ilamı"
                break;
            case IlamTipi.arabulucu:
                aciklama = alacak.ilami_veren_kurum + "'nin " + this.$helper.tarihStr(alacak.karar_tarihi) + " tarih ve " + alacak.esas_no + " Esas, " + alacak.karar_no + " Karar sayılı ilamı"
                break;
            case IlamTipi.noter:
                aciklama = alacak.noter?.isim + "'nin " + alacak.yevmiye_no + " Karar sayılı  " + alacak.karar_tarihi + " Karar sayılı ilamı" //noter id yazdım adı olması lazım
                break;
            case IlamTipi.mahkeme:
                aciklama = alacak.mahkeme?.isim + "'nin " + this.$helper.tarihStr(alacak.karar_tarihi) + " tarih ve " + alacak.esas_no + " Esas, " + alacak.karar_no + " Karar sayılı ilamı" //mahkeme id yazdım adı olması lazım
                break;
            case IlamTipi.hakem_karari:
                aciklama = alacak.ilami_veren_kurum + "'nin " + this.$helper.tarihStr(alacak.karar_tarihi) + " tarih ve " + alacak.karar_no + " Karar sayılı ilamı"
                break;
        }
        return aciklama;

    }

    private getIlamOzetiDataAciklamasi(props: Array<any>): string {
        let alacak = props[0];

        let aciklama = "";
        if (!!alacak.ilam_ozeti) {

            aciklama += "<br>" + alacak.ilam_ozeti;
        }
        return aciklama;

    }

//#endregion AltinciMadde

//#region YedinciMadde

    private getKiraKontratiDataAciklamasi(alacakKalemi: any): string {
        if (alacakKalemi[0].alacak_kalemi_turu_id == AlacakKalemiTuru.kiraAlacagi) {
            return (this.$helper.tarihStr(alacakKalemi[0].vade_tarihi) ?? "") + " tarihli " + this.$helper.tutarStr(alacakKalemi[0].tutar, alacakKalemi[0].para_birimi) + " değerinde kira alacağı";
        }
        return "";
    }

    private getTahliyeTaahhuduDataAciklamasi(props: Array<any>): string {
        let alacak = props[0];
        return alacak.adres_detayi + " " + alacak.sehir.isim + "/" + alacak.ilce.isim + " adresinde bulunan kiralanmış taşınmaz için düzenlenmiş, " + this.$helper.tarihStr(alacak.sozlesme_tarihi) + " Hazırlanış Tarihli, " + this.$helper.tarihStr(alacak.sozlesme_baslangic_tarihi) + " Başlangıç Tarihli, " + this.$helper.tarihStr(alacak.sozlesme_bitis_tarihi) + " Sonlanma Tarihli, " + this.$helper.tutarStr(alacak.bir_yillik_kira_bedeli) + " Meblağlı Kontrat."
    }

//#endregion YedinciMadde

//#region SekizinciMadde

    private getAlacakDataAciklamasi(props: Array<any>): string {
        let alacak = props[0];
        if (alacak.alacak_belge_turu_id == AlacakBelgeTuru.cocukTeslimi || alacak.alacak_belge_turu_id == AlacakBelgeTuru.kisiselIliski) {
            return alacak.belge_aciklamasi;
        }
        // if (alacak.alacak_belge_turu_id == AlacakBelgeTuru.tahliyeTaahhudu) {
        //     return alacak.aciklama + "\n" + this.$helper.tarihStr(alacak.sozlesme_tarihi) + " tarihli tahliye taahhütü";
        // }
        if (alacak.ilam_tipi) {
            return "";
        }

        return alacak.aciklama + (alacak.kontrat_aciklamasi ? " Kontrat Açıklaması : " + alacak.kontrat_aciklamasi : " ");
    }

//#endregion SekizinciMadde

//#region DokuzuncuMadde


    private getTakipYoluDataAciklamasi(props: Array<any>): string {
        let takipTalebi = props[0];
        if (takipTalebi.takip_yolu.id === 18) {
            return takipTalebi.manuel_takip_yolu
        }
        return takipTalebi.takip_yolu.isim
    }


//#endregion DokuzuncuMadde


}

