import { render, staticRenderFns } from "./AlacakliOlduguDosyaHacizForm.vue?vue&type=template&id=57d0cdf6&scoped=true"
import script from "./AlacakliOlduguDosyaHacizForm.vue?vue&type=script&lang=ts"
export * from "./AlacakliOlduguDosyaHacizForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d0cdf6",
  null
  
)

export default component.exports