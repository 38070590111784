
    import {Component, Mixins} from "vue-property-decorator";
    import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
    import Dates from "@/components/inputs/Dates.vue";

    @Component({
        components: {
            Dates,
        }
    })
    export default class MarkaTuruPicker extends Mixins(SimpleInputMixin) {
        items:any[] = [];
        async getMarkaTurleri(){
            let response :any = await this.$http.get("/api/v1/marka-turu");
            this.items = response.data;
        }
        async mounted() {
            await this.getMarkaTurleri();
        }
    };
