
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  directives: {
    mask
  },
})
export default class IbanNumarasi extends Mixins(SimpleInputMixin) {
  @Prop() label!:string;
  mask = "TR## #### #### #### #### #### ##";

  rules = [
    (value: any) => {
      return !!value || 'Lütfen bu alanı doldurun.'
    },
    (value: any) => {
      return (value && value.length == 32) || "Geçersiz IBAN No.";
    }
  ];


  validate() {
    this.$emit("value", this.localValue);
  }

  @Watch('localValue')
  onLocalValChange() {
    this.input();
  }

};
