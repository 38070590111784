
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MasrafCesidiPicker from "@/components/pickers/MasrafCesidiPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "@/components/inputs/Dates.vue";
import {MasrafCesidiAlani} from "@/enum/MasrafCesidiAlani";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";

@Component({
  components: {MasrafCesidiPicker, FormWrapper, Dates, Tutar},
})
export default class MasrafForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) takipTarihi!: any;
  @Prop() alacakId!: number;
  @Prop() takipDurumu !: string;
  @Prop() masrafCesidiAlani!: MasrafCesidiAlani;
  @Ref('formWrapper') formWrapper!: FormWrapper;

  takipSonrasi: boolean = false;
  tarihKontrol: boolean = false;
  masrafCesitleri = []

  date = new Date().toUTCString()


  alacakKalemi !: any;

  mounted(){
    this.birGunCikar();
    if (this.takipDurumu != TakipDurumu.acilmamis) {
      this.takipSonrasi = true;
    }
    this.loadMasrafCesitleri()
  }
  loadMasrafCesitleri() {
    this.$http.get("/api/v1/masraf-cesidi").then((response) => {
      this.masrafCesitleri = response.data.filter((masraf:any) => masraf.tutar>0.00);
      console.log(this.masrafCesitleri)
    });
  }
  save() {
    this.formWrapper.onFly = true;
    if (this.localValue.id) {
      this.$http.put('/api/v1/alacak-kalemi/' + this.localValue.id, this.localValue)
          .then((response) => {
            this.$emit('onSuccess')
          }).finally(() => (this.formWrapper.onFly = false));
    } else {
      if (this.takipDurumu == TakipDurumu.acilmamis) {
        this.localValue.alacak_kalemi_alani_id = AlacakKalemiAlani.takipOncesiMasraf;
      } else {
        this.localValue.alacak_kalemi_alani_id = AlacakKalemiAlani.takipsonrasiMasraf;
      }
      this.localValue.alacak_id = this.alacakId;
      this.localValue.masraf_tutari = this.localValue.tutar;
      this.localValue.kur = 1;
      this.localValue.para_birimi_id = 1;
      this.$http.post('/api/v1/alacak/' + this.alacakId + '/alacak-kalemi', this.localValue)
          .then((response) => {
            this.$emit('onSuccess')
          }).finally(() => (this.formWrapper.onFly = false));
    }

  }

  onMasrafCesidiChange() {
    if (this.localValue.masraf_cesidi_id) {
      this.localValue.tutar = Number(this.localValue.masraf_cesidi?.tutar) > 0 ? this.localValue.masraf_cesidi?.tutar : "";
      this.localValue.gayri_resmi = this.localValue.masraf_cesidi?.gayri_resmi;
    }

    this.masrafCesitleri.forEach((masraf:any) => {

      if (this.localValue.masraf_cesidi_id == masraf.id) {
        this.localValue.tutar = masraf.tutar;
      }
    })
  }
  birGunCikar()
  {
    let gun = new Date(this.takipTarihi).setDate(new Date(this.takipTarihi).getDate() - 1)
    let myDate = new Date(gun).toISOString().substr(0, 10);
    return myDate
  }

  @Watch('localValue.tarih')
  onMasrafTarihi() {
    if (this.localValue.tarih > this.takipTarihi) {
      this.tarihKontrol = true;
      this.input();
    } else {
      this.tarihKontrol = false;
    }
  }

}

