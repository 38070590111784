import { render, staticRenderFns } from "./PostaCekiHacizForm.vue?vue&type=template&id=c841b378&scoped=true"
import script from "./PostaCekiHacizForm.vue?vue&type=script&lang=ts"
export * from "./PostaCekiHacizForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c841b378",
  null
  
)

export default component.exports