import {IhaleAlicisi} from "@/enum/IhaleAlicisi";
import {KabulRet} from "@/enum/KabulRet";
import {SatisYapilamamaSebebi} from "@/enum/SatisYapilamamaSebebi";
import {MahkemeEntity} from "@/entity/MahkemeEntity";

export class SatisEntity {
    id: number | null = null;
    baslangic_tarihi!: Date;
    baslangic_saati: string | null = null;
    bitis_tarihi: Date | null = null;
    bitis_saati: string | null = null;
    satis_yapildi_mi: boolean = false;
    ihale_alicisi: IhaleAlicisi = IhaleAlicisi.alacakli_vekili;
    ucuncu_sahis_adi_soyadi_unvani: string = "";
    ihale_kesinlesti_mi: boolean = false;
    ihale_kesinlesme_tarihi: Date | null = null;
    ihale_bedeli_yatirildi_mi: boolean = false;
    ihale_bedeli: number = 0;
    muhafaza_ve_bakim_giderleri: number = 0;
    ihalenin_feshi_davasi_var_mi: boolean = false;
    satis_talebi_id: number | null = null;
    muhammen_bedel:any;
    mahkeme !: MahkemeEntity;
    mahkeme_id !: number | null;
    satis_no!:number
    ihalenin_feshi_davasi_sonucu: KabulRet = KabulRet.ret;
    satis_yapilamama_sebebi: SatisYapilamamaSebebi = SatisYapilamamaSebebi.borc_odendi;
    manuel_satis_yapilamama_sebebi: string = "";
    satis_masrafi:any
}