
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import {BorcluEntity} from "@/entity/BorcluEntity";


@Component({
  components: {}
})
export default class PostaCekiHacizForm extends Mixins(ObjectInputMixin) {
  @Prop() kisiKurumId!: number;
  @Prop() dosyaId!: number;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.$uyap.BorcluPostaCeki().run({
        dosyaId: "1212",//this.borclu.takip.uyap_dosya_id,
        kisiKurumId: 1212,//this.borclu.kisi.uyap_kisi_kurum_id,
        sorguBakiye: '',
      }).then(response => {
        this.localValue.uyap_data.postaCekiExist = response.sorguSonucu;
        this.input();
      });
    }
  }

}
