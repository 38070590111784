
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipTipi} from "@/enum/TakipTipi";
import TasinirlarPicker from "@/components/pickers/TasinirlarPicker.vue";
import TasinmazlarPicker from "@/components/pickers/TasinmazlarPicker.vue";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";


@Component({
  components: {TasinmazlarPicker, TasinirlarPicker, FormWrapper}
})
export default class SatisKopyalaForm extends Mixins(SimpleInputMixin) {
  @Prop() takip!: TakipEntity;
  @Ref('form') form!: FormWrapper;

  localValue = {kopyalanan: null, uygulanacaklar: []};
  StatusCodeHelper = new StatusCodeHelper();

  get isTasinirTakibi() {
    if (this.takip.talepler.find((talep: TakipTalebiEntity) => (talep.takip_tipi_id == TakipTipi.menkulRehni || talep.takip_tipi_id == TakipTipi.tasinirRehni)))
      return true;
    else
      return false;
  }

  async save() {
    this.form.onFly=true;
    for (const uygulanacak of this.localValue.uygulanacaklar){
      let kopya:any = this.localValue.kopyalanan;
      if (this.isTasinirTakibi)
        kopya.tasinir_id=uygulanacak;
      else
        kopya.tasinmaz_id=uygulanacak;
      await this.$http.post('/api/v1/takip/'+this.takip.id+'/satis', kopya).catch(error => this.StatusCodeHelper.error(error));
    }
    this.form.onFly=false;
    this.$emit('onSuccess');
  }
}
