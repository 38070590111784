

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IArac} from "@/plugins/uyap-plugin/uyap/DosyaBorcluEgm";

@Component({
  components: {}
})

export default class DosyaBorcluEgmPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) dosyaId!: string;
  @Prop({required: true}) kisiKurumId!: number;
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<IArac> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onAracChange(v: IArac) {
    this.$emit('plakaYildizli', (this.items.find(item => item.plakaSifreli == this.localValue))?.plaka);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    let items = (await this.$uyap.DosyaBorcluEgm().run({
      dosyaId: this.dosyaId,
      kisiKurumId: this.kisiKurumId,
      sorguBakiye: '',
      pageIndex: 0
    })).sorguSonucDVO?.aracBilgileri.output;
    if (items)
      this.items = items;
  }
}
